<template>
  <v-sheet>
    <v-row>
      <v-col md="4" cols="6">
        <v-text-field
          ref="newKeyword"
          v-model="keywordInput"
          @keydown.enter="addKeyword"
          label="New Keyword"
          :rules="[() => !keywordExists || 'Keywords already exists']"
        ></v-text-field>
      </v-col>
      <v-col class="hidden-sm-and-down"></v-col>
      <v-col cols="1" sm="12">
        <v-btn color="success" @click="save" :disabled="!canSave"> Save </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <sheet title="To Add" class="">
          <v-chip
            small
            class="mr-2"
            v-for="keyword in toAdd"
            close
            @click:close="removeKeywordFromAdd(keyword)"
            :key="keyword"
            >{{ keyword }}</v-chip
          >
        </sheet>
      </v-col>
      <v-col cols="12">
        <sheet title="To Delete">
          <v-chip
            small
            class="mr-2"
            v-for="keyword in toRemove"
            :key="keyword.name"
            close
            @click:close="restoreKeyword(keyword)"
            >{{ keyword.name }}</v-chip
          >
        </sheet>
      </v-col>
      <v-col cols="12" lg="6" offset-lg="3">
        <!-- <sheet title="Current keywords">
          <v-chip
            small
            class="mr-2"
            v-for="keyword in orderedKeywords"
            :key="keyword.name"
            close
            @click:close="removeKeyword(keyword)"
            >{{ keyword.name }}
            
          </v-chip>
        </sheet> -->
        <v-data-table
          :headers="headers"
          :items-per-page="-1"
          hide-default-footer
          :items="orderedKeywords"
        >
          <template v-slot:item.action="{ item }">
            <v-btn icon @click="removeKeyword(item)"
              ><v-icon>mdi-delete</v-icon></v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
import Sheet from "../../components/Shared/Sheet.vue";
export default {
  components: { Sheet },
  data() {
    return {
      keywordInput: null,
      toAdd: [],
      toRemove: [],
      keywords: [],
    };
  },
  computed: {
    canSave() {
      return this.toAdd.length + this.toRemove.length > 0;
    },
    headers() {
      var isMobile = this.$vuetify.breakpoint.mobile;
      var action = { text: "", value: "action", width: "10%" };
      var bookCount = { text: "# Related Book", value: "bookCount" };
      var bookTitle = { text: "Keyword", value: "name" };
      var h = [];

      if (isMobile) {
        h = [bookTitle, bookCount, action];
      } else {
        h = [action, bookTitle, bookCount];
      }
      return h;
    },
    keywordExists() {
      var n = this.keywordInput;
      return (
        this.toAdd.some((x) => x === n) ||
        this.keywords.some((x) => x.name === n)
      );
    },
    orderedKeywords() {
      var keywords = [].concat(this.keywords);

      return keywords.sort((a, b) => a.name - b.name);
    },
  },
  watch: {},
  methods: {
    addKeyword() {
      var n = this.keywordInput.toLowerCase();
      if (!this.keywordExists) {
        this.toAdd.push(n);
        this.$refs["newKeyword"].reset();
      }
    },
    loadKeywords() {
      this.$store
        .dispatch("utils/loadKeywords", { withBookCount: true })
        .then(() => {
          this.keywords = this.$store.getters["utils/keywords"];
        });
    },
    removeKeywordFromAdd(item) {
      var index = this.toAdd.indexOf(item);
      if (index >= 0) {
        this.toAdd.splice(index, 1);
      }
    },
    removeKeyword(item) {
      var index = this.keywords.indexOf(item);
      if (index >= 0) {
        var toDel = this.keywords.splice(index, 1);
        if (toDel.length > 0) this.toRemove.push(toDel[0]);
      }
    },
    restoreKeyword(item) {
      var index = this.toRemove.indexOf(item);
      if (index >= 0) {
        var toDel = this.toRemove.splice(index, 1);

        if (toDel.length > 0) {
          this.keywords.push(toDel[0]);
        }
      }
    },
    save() {
      let toSave = {};
      this.toAdd.forEach((x) => {
        toSave[x] = true;
      });
      this.toRemove.forEach((x) => {
        toSave[x] = false;
      });
      this.$store.dispatch("utils/saveKeywords", toSave).then(() => {
        this.loadKeywords();
        this.toAdd = [];
        this.toRemove = [];
      });
    },
  },
  created() {
    this.loadKeywords();
  },
};
</script>