<template>
  <v-sheet>
    <h1>Control Panel</h1>
    <v-tabs>
      <v-tab>Keywords</v-tab>
      <v-tab-item>
        <keywords-configuration class="mt-6"></keywords-configuration>
      </v-tab-item>
    </v-tabs>
  </v-sheet>
</template>
<script>
import KeywordsConfiguration from "./KeywordConfiguration.vue";
export default {
  components: { KeywordsConfiguration },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>